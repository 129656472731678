import React from "react"
import { Link, graphql } from "gatsby"

const errorContent = ({ data }) => (
  <div class="errorPage">
    <div className="pageContent">
      <h1>So yeah...About that page...</h1>
      <p>
        If you land on a page, and no one was around to build it... would it
        still load?
      </p>

      <p>
        Only the internet gods know...either way you wouldn't see much beyond
        what you're seeing now. But don't wallow in despair because the most
        interesting journeys are the ones you get lost in
      </p>
    </div>
    <div className="side">
      <h1>Pages</h1>
      <p>
        Perhaps you will find what you are looking for in one of these pages:
      </p>
      <ul>
        {data.allSitePage.edges.map(({ node }) => (
          <li key={node.id}>
            <Link to={node.path}>{node.path}</Link>
          </li>
        ))}
      </ul>
    </div>
  </div>
)
export default errorContent;

export const query = graphql`
  {
    allSitePage(sort: { fields: path }, skip: 3) {
      edges {
        node {
          path
          id
        }
      }
    }
  }
`
